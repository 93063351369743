/*
    JHERONIMUS StyleGuide - 2016.1
    Variables
*/
/*
    #### COLORS ####
*/
@mpHeaderBackground: #007ebc;

@button-green: #6FB625;
@button-green-color: #6FB625;
@button-blue-color: #3488D2;
@button-grey-color: #f1f1f1;
@button-red-color: #FD3D30;
@button-font-color: #fff;
@button-grey-font-color: #888;
@button-darkgrey-color:#d3d3d3;
@button-yellow-color: #FFA500;

@font-color: #666;
@font-color-light: #999;
@font-color-lighter: #bbb;
@font-color-dark: #5e5e5e;
@font-color-darker: #222;
@font-color-title: #3488D2;

@border-color: #E0E0E0;
@border-color-light: #DBDBDB ;
@background-grey: #eaeaea;
@background-grey-lighter: #f1f1f1;
@background-progress-bar: #E1E1E1;

@background-list-grey: #f2f2f2;
@background-list-grey-light: #f7f7f7;
@background-list-grey-lighter: #f9f9f9;

@red-accent: #c60000;
@blue-accent: #3488D2;
@green-accent: #6FB625;
@orange-accent: #F09100;
@fiep-pink: #e3017a;
@grey-accent: #606060;
@purple-accent: #B6778A;
@icon-color:transparent;
@icon-border-color:@border-color;

@active-color: #007ebc;
@hover-grey:#f7f7f7;

@mp-blue: @active-color;
@fiep-magenta: #E4017A;
@outgoing-orange: @orange-accent;
@warningbox-backgroundcolor: #FFFED5;

/*
    MEDIA QUERIES
*/
@is-mobile: ~ "only screen and (max-width: 760px)";
@is-mobile-landscape: ~ "only screen and (max-width: 760px) and (orientation landscape)";
@is-desktop: ~ "only screen and (min-width: 760px)";
@is-minimal-mobile: ~ "only screen and (max-width: 640px)";
@is-minimal-desktop: ~ "only screen and (min-width:640px)";
/*
    ### FONT-SIZE ####
*/
@size-H1: 2.4rem;
@size-H2: 2.0rem;
@size-H3: 1.5rem;
@size-extra-extra-extra-large: 3.5rem;
@size-extra-extra-large: 2.2rem;
@size-extra-large: 1.8rem;
@size-larger: 1.5rem;
@size-large: 1.4rem;
@size-medium: 1.3rem;
@size-small: 1.2rem;
@size-extra-small: 1.1rem;

@font-family: 'Open Sans', sans-serif;
@small-font-family: 'Source Sans Pro', sans-serif;
@title-font-family: 'PT Sans', sans-serif;
@alternate-title-font-family: 'Poppins', sans-serif;

@font-source-sans: @small-font-family;
@font-open-sans: @font-family;
@font-pt-sans: @title-font-family;

@border-radius: 8px;
@border-radius-small: 4px;
// #########
/*
    Images
*/
@icons-sprite: url('../../../Images/StyleGuides/Jheronimus/icons.png');


.overFlowEllipsis() {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.absoluteCenterY() {
    position: absolute;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* FUNCTIONS */
.generate-overlaid-avatars(@maxNumber, @index: 1) when(@index =< @maxNumber) {
	@nth: @index+1;

	.avatar:nth-child(@{nth}) {
		transform: translateX(@index*-33%);
	}

	.generate-overlaid-avatars(@maxNumber, @index+1);
}

/* STYLED LISTS */
@list-item-background-color: @background-grey-lighter;
@list-item-border-color: @border-color;
@list-item-inactive-background-color: @background-list-grey-lighter;
@list-item-inactive-text-color: #588FD3;
@list-gutter: 8px;


.wt-semi() {
    font-weight: 600;
}

.wt-bold(){
    font-weight: 700;
}

.wt-regular() {
    font-weight: 400;
}

.ft-source-sans(){
    font-family: @font-source-sans;
}
.ft-pt-sans() {
    font-family: @font-pt-sans;
}
.ft-open-sans(){
    font-family: @font-open-sans;
}
.ft-poppins() {
    font-family: @alternate-title-font-family;
}

.truncating-text() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ft-title() {
    .ft-poppins();
    font-weight: 600;
    font-size: 2.3rem;
}
@white: #ebf4f9;

html {
    font-size: 62.5%;
    min-height: 100%;
    background: @mp-blue;
}

body {
    font-size: 1.5rem;
    background: none;
}

h1 {
    color: @white;
    font-size: 2.3rem;
    text-align: left;
    margin: 0;
    margin-bottom: 0.5em;
}

p {
    margin-top: 1em;
}

#page,
#block-wrapper {
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    background: none;
    color: @white;
    .ft-open-sans();
}

body.security {
    height: 100%;
    margin: 0;
    min-width: 280px;

    #header,
    .menu-wrapper {
        display: none;
    }

    h2 {
        margin-bottom: 20px;
    }

    #page,
    #block-wrapper {
        box-sizing: border-box;
        height: 100%;
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 125px 1fr auto;
        gap: 15px 0px;

        @media screen and (min-width: 375px) {
            padding: 40px 20px;
        }

        .c-input-group {
            margin-bottom: 10px;
        }

        .reset-password {
            display: block;
        }

        .c-btn {
            margin: 10px 0;
        }

        #disclaimer {
            text-align: center;
            color: @white;
            font-size: 1.3rem;
            margin: 0;
            padding: 20px 0;

            .ziber-logo {
                display: flex;
                flex-direction: column;
            }

            .logo {
                width: 28px;
                height: 28px;
                background-image: url("../../Assets/Images/ziber_heart.svg");
            }
        }

        .logo-wrapper {
            justify-self: center;
            align-self: top;
            display: flex;
            flex-direction: column;
            align-items: center;
            user-select: none;
            font-family: @font-source-sans;
            font-size: 19px;
            line-height: 1;

            .logo {
                margin-bottom: 10px;
                display: block;
                width: 150px;
                height: 71px;
                background-image: url("../../Assets/Images/ziber_logo_white.svg");
                background-repeat: no-repeat;
                background-position: center center;
            }
        }

        .o-form-container {
            @media @is-minimal-desktop {
                margin-top: 40px;
            }
        }

        .o-create-container {
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            min-height: 135px;

            .o-create-button {
                border-radius: 5px;
                background-color: #f7f7f7;
            }
        }

        .login-ZiberID {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;

            div {
                display: inline-block;
                flex-grow: 1;
                flex-shrink: 0;
            }

            svg {
                margin-left: 3px;
                vertical-align: top;

                path:nth-of-type(1) {
                    fill: rgba(255, 255, 255, 1);
                }

                path:nth-of-type(2) {
                    fill: rgba(255, 255, 255, 0.5);
                }
            }

            .login-ziberID-explanation {
                font-size: 1.2rem;
                flex-grow: 0;
                flex-shrink: 0;
                padding: 12px 0;

                a {
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    border-radius: 6px;
                    padding: 6px 14px;

                    span {
                        color: rgba(255, 255, 255, 0.75);
                        font-weight: 600;
                        margin: 20px 3px;
                    }
                }
            }
        }
    }

    #reply-management-invitation {
        margin-bottom: 40px;
    }

    .validation-summary {
        &.validation-summary-valid {
            display: none;
        }

        padding: 14px 18px;
        margin: 0;

        &-errors {
            background-color: #bd2d2d;
            .wt-semi();
            font-size: 1.5rem;
            color: white;
        }

        ul {
            @media @is-desktop {
                margin-left: 30px;
            }
        }

        &::before {
            font-family: "fontello";
            font-weight: 900;
            color: white;
            content: "\e800";
            font-size: 2rem;
            position: absolute;
        }
    }

    .c-btn--back {
        display: block;
        align-self: flex-start;
        border-radius: 8px;
    }

    .c-note {
        color: red;
        text-shadow: 1px 1px 1px #222;
        font-size: 1.1rem;
    }

    .ziber-logo {
        margin: 0;
        text-align: center;
        .ft-open-sans();
        .wt-semi();
        font-size: 1.3rem;
        opacity: 0.8;
        margin-bottom: 30px;

        img {
            margin-bottom: 5px;
        }
    }

    .c-input-group {
        color: #ebf4f9;
        border-radius: @border-radius-small;
        position: relative;

        &__label {
            font-size: 1.9rem;
            display: inline-block;
            font-weight: 400;
            margin: 0;
            color: inherit;
        }

        input ~ .c-input-group__label {
            position: absolute;
            top: 14px;
            left: 20px;
            transition: all 0.25s ease-in-out;
            pointer-events: none;
        }

        input:focus ~ .c-input-group__label,
        input:not(:placeholder-shown) ~ .c-input-group__label {
            top: 6px;
            font-size: 90%;
            color: rgba(235, 244, 249, 0.7);
        }

        input,
        textarea {
            padding-top: 2.8rem !important;
            background: rgba(0, 87, 131, 1);
            font-size: 1.9rem;
            border: 1px solid transparent !important;
            caret-color: @mp-blue;
            position: relative;
            height: 56px !important;
            color: inherit;
            font-weight: 400;
            box-shadow: none;

            &::placeholder,
            &:placeholder-shown {
                color: transparent;
            }

            &:read-only {
                background: rgba(0, 87, 131, 0.5);
                cursor: not-allowed;
            }
        }

        input:-webkit-autofill::first-line {
            font-size: 1.9rem !important;
        }

        input,
        textarea,
        select {
            &:-webkit-autofill {
                font-size: 1.9rem !important;
                font-weight: 400 !important;
                -webkit-text-fill-color: rgb(235, 244, 249) !important;

                &,
                &:hover,
                &:focus {
                    background-color: transparent !important;
                    -webkit-box-shadow: 0 0 0 50px rgb(0, 87, 131) inset;
                    border: 1px solid rgb(0, 87, 131) !important;
                    color: rgb(235, 244, 249) !important;
                }
            }
        }

        label.c-input-group--is-invalid {
            color: #fff;
            background-color: #bd2d2d;
            padding: 5px 10px;
            margin-top: 5px;
            border-radius: @border-radius-small;
            font-size: 1.3rem;
            font-weight: 400;
        }
    }

    .o-button-container {
        display: block;

        @media @is-minimal-desktop {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;

            > button {
                flex-grow: 0;
                flex-shrink: 1;
            }
        }
    }

    a.o-href-alternative {
        position: relative;
        color: #fff;
        transition-duration: 0.2s;
        transition-property: opacity, color;
        transition-timing-function: ease-in-out;

        &::before {
            text-decoration: none;
            background-color: currentColor;
            border-radius: 1px;
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.2s ease-in-out;
            height: 1px;
            width: 100%;
        }

        &:hover {
            color: #fff;
            opacity: 1;
        }

        &:hover::before {
            transform: scaleX(1);
            transform-origin: left;
        }
    }
}

/* Styles for error pages. To be moved when convenient */
#panel.error {
    text-align: center;
    margin: 0 auto;

    .errorMessage {
        text-shadow: 0px 1px 0px #444;
        font-size: 3.2rem;
        .ft-open-sans();
        .wt-semi();
        margin-bottom: 20px;
    }

    .message {
        .ft-source-sans();
        opacity: 0.8;

        a {
            text-decoration: underline;
        }
    }
}

#reply-management,
#login-excisting-account {
    .wannabe-CUI {
        margin-bottom: 15px;

        .wannabe-CUI-contentwrapper {
            display: flex;
            gap: 12px;
            flex-wrap: nowrap;

            .avatar {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 48px;

                img {
                    max-width: 48px;
                    max-height: 48px;
                    border-radius: 50%;
                    border: solid 1px #fff;
                }
            }

            .message-container {
                h1 {
                    color: #ebf4f9;
                    font-size: 2.3rem;
                }

                .text {
                    font-size: @size-H3;
                    color: #ebf4f9;
                    margin: 0px;

                    span {
                        color: #ebf4f9;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .o-checkbox-wrapper {
        display: flex;
        flex-flow: row nowrap;
        cursor: pointer;
        gap: 12px;
        align-items: center;
    }
}

#request-for-management {
    .text {
        font-size: @size-H3;
        color: #555;
        margin: 0px;

        span {
            color: @blue-accent;
            font-weight: bold;
        }
    }

    .buttonsContainer {
        margin: 10px 0 30px 0;
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin-top: 75px;

        .text {
            width: 100%;
        }

        button {
            flex: 1;
            margin-top: 0px;

            &.accept {
                border: 1px solid #6fb625;
            }

            + button {
                margin-left: 7px;
            }
        }
    }
}
